// bootstrap-sass and 3rd-party component styles
@import 'bootstrap.min';

body {
  color: #363c44;
  font-family: 'proxima_nova', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 140%;
}

p {
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 140%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
}

.global-header-v2-wrapper .splunk-header-v2 .splunk-navbar .navbar-nav.navbar-right.bottom-nav {
  margin-top: 0 !important;
}

.global-header-v2-wrapper .splunk-header-v2 .splunk-navbar .container-fluid .navbar-nav > li {
  padding-bottom: 0 !important;
  font-size: 18px;
}

.splunk2-h1 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 35px;
  line-height: 100%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk2-h2 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk2-h3 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk2-h4 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk2-h5 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk2-h6 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk2-h7 {
  color: #000;
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 15px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
  letter-spacing: 0.25px;
}
#content .splunk2-eyebrow {
  font-family: 'proxima_nova', Arial, sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #f0f3f7 !important;
  padding-bottom: 10px;
  text-transform: uppercase;
  margin: 0;
}
.inverted,
.inverted p {
  color: #fff;
}
.bottom-spacing {
  margin-bottom: 60px;
}
/*Black Button*/
#content {
  .sp-btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  .sp-btn-black:focus,
  .sp-btn-black.focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  .sp-btn-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  .sp-btn-black:active,
  .sp-btn-black.active,
  .open > .dropdown-toggle.sp-btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  .sp-btn-black:active:hover,
  .sp-btn-black.active:hover,
  .open > .dropdown-toggle.sp-btn-black:hover,
  .sp-btn-black:active:focus,
  .sp-btn-black.active:focus,
  .open > .dropdown-toggle.sp-btn-black:focus,
  .sp-btn-black:active.focus,
  .sp-btn-black.active.focus,
  .open > .dropdown-toggle.sp-btn-black.focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  .sp-btn-black:active,
  .sp-btn-black.active,
  .open > .dropdown-toggle.sp-btn-black {
    background-image: none;
  }

  .sp-btn-black.disabled:hover,
  .sp-btn-black[disabled]:hover,
  fieldset[disabled] .sp-btn-black:hover,
  .sp-btn-black.disabled:focus,
  .sp-btn-black[disabled]:focus,
  fieldset[disabled] .sp-btn-black:focus,
  .sp-btn-black.disabled.focus,
  .sp-btn-black[disabled].focus,
  fieldset[disabled] .sp-btn-black.focus {
    background-color: #000;
    border-color: #000;
  }

  .sp-btn-black .badge {
    color: #000;
    background-color: #fff;
  }

  .sp-btn-black:hover {
    background-color: #72777a;
    border-color: #000;
  }

  .inverted .splunk2-h1,
  .inverted .splunk2-h2,
  .inverted .splunk2-h3,
  .inverted .splunk2-h4,
  .inverted .splunk2-h5,
  .inverted .splunk2-h6,
  .inverted .splunk2-h7 {
    color: #ffffff;
  }
}

@media (min-width: 480px) {
  .splunk2-h1 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .splunk2-h2 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 100%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .splunk2-h3 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 27px;
    line-height: 100%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .splunk2-h4 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .splunk2-h5 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .splunk2-h6 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .splunk2-h7 {
    color: #000;
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  #content {
    .splunk2-h1 {
      color: #000;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 55px;
      line-height: 110%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-h2 {
      color: #0c1724;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 110%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-h3 {
      color: #0c1724;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 110%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-h4 {
      color: #0c1724;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 110%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-h5 {
      color: #0c1724;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 110%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-h6 {
      color: #0c1724;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 110%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-h7 {
      color: #0c1724;
      font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      -webkit-font-smoothing: antialias;
      padding-bottom: 15px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .splunk2-eyebrow {
      font-size: 14px;
      line-height: 100%;
    }
    .tabs-container {
      padding-right: 42px;
      padding-left: 42px;
      padding-top:20px;
    }
  }
}

a:hover {
  text-decoration: none;
}
.splunk-h2 {
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 110%;
  -webkit-font-smoothing: antialias;
  padding-bottom: 25px;
  margin-bottom: 0;
  margin-top: 0;
}
.splunk-btn {
  transition: background-color 0.5s;
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;
  margin-bottom: 10px;
}
.sp-btn-pink {
  color: #fff;
  background-color: #ED0080;
  border-color: #ED0080;
  color: #fff !important;
  background-color: #ED0080 !important;
  border-color: #ED0080 !important;
}

.splunk-btn:last-child {
  margin-right: 0;
}
.sp-btn-white-hollow:hover,
.sp-btn-white-hollow:focus {
  color: #2eb50b;
  background-color: #fff;
  border-color: #fff;
  text-decoration: none;
}
.breadcrumb {
  margin-bottom: 0;
}
.white-background-buttons {
  background-color: #fff;
  display: flex;
  padding-top: 10px;
  box-shadow: 30px 0 0 #fff, -30px 0 0 #fff;
  -moz-box-shadow: 30px 0 0 #fff, -30px 0 0 #fff;
  -webkit-box-shadow: 30px 0 0 #fff, -30px 0 0 #fff;
  box-decoration-break: clone;
  -moz-box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  margin-top: 7px;
  justify-content: center;
}
.sp-btn-black-hollow {
  color: #000;
  background-color: transparent;
  border-color: #000;
}

ul.splunk-list {
  list-style: none;
  padding-left: 25px;
}

ul.splunk-list li {
  padding-left: 18px;
  text-align: left;
  margin-bottom: 12px;
  position: relative;
}

ul.splunk-list li:before {
  font-size: 1.5em;
  content: '• ';
  display: inline-block;
  left: -2px;
  margin-right: -15px;
  width: 1.3em;
  position: absolute;
  top: 0;
}

ul.splunk-list li ul.splunk-body {
  padding-bottom: 0;
  padding-top: 8px;
}

ul.splunk-list.splunk-body.list-arrow {
  padding-left: 13px;
}

ul.splunk-list.splunk-body.list-arrow li {
  padding: 0;
  padding-left: 30px;
  list-style-type: none;
}

ul.splunk-list.splunk-body.list-arrow li:before {
  font-size: 1em;
  content: '\e907';
  font-family: 'icomoon';
  display: inline-block;
  left: 6px;
  margin-right: -1px;
  width: 1.3em;
  position: absolute;
  top: 0;
}

ul.splunk-list.splunk-body.list-arrow2 {
  padding-left: 0;
}

ul.splunk-list.splunk-body.list-arrow2 li {
  padding: 0;
  padding-left: 22px;
  list-style-type: none;
}

ul.splunk-list.splunk-body.list-arrow2 li:before {
  font-size: 1em;
  content: '\00BB';
  font-family: 'icomoon';
  display: inline-block;
  left: 6px;
  margin-right: -1px;
  width: 1.3em;
  position: absolute;
  top: -4px;
}

ul.splunk-list.splunk-body.list-check {
  padding-left: 13px;
}

ul.splunk-list.splunk-body.list-check li {
  padding-left: 30px;
  list-style-type: none;
}

ul.splunk-list.splunk-body.list-check li:before {
  font-size: 1em;
  content: '\e90b';
  font-family: 'icomoon';
  display: inline-block;
  margin-left: -1.3em;
  margin-right: 3px;
  width: 1.3em;
  position: absolute;
  top: 0;
  left: 26px;
}

ul.splunk-list.splunk-body.list-pdf {
  padding-left: 13px;
}

ul.splunk-list.splunk-body.list-pdf li {
  padding-left: 30px;
  list-style-type: none;
}

ul.splunk-list.splunk-body.list-pdf li:before {
  font-size: 1em;
  content: '\e905';
  font-family: 'icomoon';
  display: inline-block;
  margin-left: -1.3em;
  margin-right: 3px;
  width: 1.3em;
  position: absolute;
  top: 0;
  left: 30px;
}

ul.splunk-list.splunk-list-gray li:before {
  color: #97999b;
}

ul.splunk-list.splunk-list-green li:before {
  color: #65a637;
}

ul.splunk-list.splunk-list-cta-green li:before {
  color: #2eb50b;
}

ul.splunk-list.splunk-education-list-green {
  padding-left: 0;
}

ul.splunk-list.splunk-list-blue li:before {
  color: #0f7bb8;
}

ul.splunk-list.splunk-list-blue-light li:before {
  color: #00a9e0;
}

ul.splunk-list.splunk-list-black li:before {
  color: #2d2d2d;
}
.splunk-color.splunk-green-cta {
  color: #2eb50b;
}
.splunk-color.splunk-blue-cta {
  color: #0070f3;
  line-height: 22px;
}

@media (min-width: 1200px) {
  .splunk-h2 {
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 50px;
    line-height: 120%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 35px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media (min-width: 480px) {
  .splunk-h2 {
    font-family: 'proxima_nova', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 110%;
    -webkit-font-smoothing: antialias;
    padding-bottom: 25px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

/*
 * *****************************************************************************
 *
 * Aha Ideas
 *
 * *****************************************************************************
 */

.ideasAuthWaitMessage {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 100px;
}

.ideasFramePlaceholder {
  border: none;
  background-color: #efefef;
  min-height: 60px;
  position: relative;
  border-radius: 10px;
  transition: color 2s ease-out;
  margin-top: 20px;
}

.ideasFrame {
  border: none;
}

.ideasLoadingIndicator {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  margin-top: 60px;
}

/*
 * *****************************************************************************
 *
 * Loading Indicator
 *
 * *****************************************************************************
 */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #666;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.page-not-found {
  padding-bottom: 30px;
}


.hero-text {
  padding-top: 45px;
  padding-left: 0;
  padding-right: 0;
}

#content .express-lane-hero p.hero-voc-title {
  font-size: 50px;
  font-weight: 500;
  line-height: 70px;
  color: #363c44;
  padding-bottom: 12px;
}

#content .express-lane-hero p.hero-voc-text {
  font-size: 18px;
}

#content p.welcome-text{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #444042;
}

.welcome-title{  
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  padding-bottom: 25px;
}

.row a.program-list-cta { 
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #000000;
}

.row a.program-list-cta:hover {
  color: #CE0070;
}

.product-list .row  {
  margin-right:0;
  margin-left:0;
}
.product-list .row .col-xs-12, .row .col-s-12 {
  margin-top: 15px;
}


.express-lane-hero .hero-voc-image img {
  width: 77%;
  margin-left: 20px;
  margin-top: 10px;
}

#content .row .program-short-desc, #content .row .program-short-desc p {
  font-weight: 600;
  font-size: 14px;
  color: #625C5F;
}

#content .date-column ul{
  padding:0
}

#content .date-column li{
  font-weight: 400;
  font-size: 14px;
  color: #363C44;
}
