.express-lane-hero {
  margin-top: 70px;
  position: relative;
  background-color: #d5dce6;  
}

.express-lane-hero .hero-voc-image-detail img {
  width: 40%;
  margin-left: 20px;
  margin-top: 30px;
}

#content .express-lane-hero h2#downloading-val, 
#content .express-lane-hero .splunk2-eyebrow, 
#content .express-lane-hero .splunk2-lead-md {
  color: #363C44 !important;
}

#content .express-lane-hero #button-link-target,
#content #send-feedback-section #button-link-target{
  color: #363C44 !important;
  border-color: #363C44 !important;
}

#content #send-feedback-section #button-link-target {
  top:0;
}

#send-feedback-section {
 margin-top:20px
}

.inverted {
  color: white;
}

.express-lane-hero .row .col-splunk-12 {
  position: relative;
}

#banner-container {
  background-color: #d5dce6;
  color: #353c44;
  background-image:none;
  padding: 43px 20px;
}

.express-lane-hero .overlay-image.bottom-background {
  background-position: right bottom;
}

.express-lane-hero .row .expresslane-hero-padding {
  padding-top: 45px;
  padding-bottom: 45px;
}

.express-lane-hero .row .expresslane-hero-padding .splunk2-h2 {
  margin-top: 5px;
  color: #fff;
}

.express-lane-hero .row .expresslane-hero-padding p a:link,
.express-lane-hero .row .expresslane-hero-padding p a:active,
.express-lane-hero .row .expresslane-hero-padding p a:visited {
  color: #2eb50b;
}

.express-lane-hero .row .expresslane-hero-padding p a:hover {
  text-decoration: underline;
}

.express-lane-hero .row .col-splunk-12 a {
  width: 220px;
}

.express-lane-hero .row .splunk-h7 {
  color: #ffffff;
}

.express-lane-hero .row .splunk-h2 {
  font-weight: normal;
  color: #ffffff;
}

.splunk-body,
.splunk-body p {
  font-family: 'proxima_nova', Helvetica, Arial, sans-serif !important;
  font-size: 18px;
  line-height: 140%;
  -webkit-font-smoothing: antialias;
  color: #363c44;
}

.splunk-body {
  padding-bottom: 25px;
}

.splunk-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

figure {
  min-height: 155px;
  border: 1px solid #cfd7db;
  position: relative;
  margin-bottom: 36px;
  background: #e3e2e2;
}

.row .expresslane-hero-padding {
  padding-top: 45px;
  padding-bottom: 45px;
}

.row .useful-tools-section {
  padding: 15px;
  background: #000000;
  border: 1px solid #979797;
  border-radius: 3px;
  margin-bottom: 65px;
  position: relative;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: bold;
}

#content .sp-btn-white-hollow {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

p {
  line-height: 140%;
  font-weight: normal;
}

.express-lane-hero .useful-tools-section {
  margin-top: 50px;
}

.express-lane-hero .row .useful-tools-section p a:link,
.express-lane-hero .row .useful-tools-section p a:active,
.express-lane-hero .row .useful-tools-section p a:visited {
  color: #2eb50b;
}

.express-lane-hero .row .useful-tools-section p {
  color: #ffffff;
  font-size: 16px;
  list-style: disc outside none;
  display: list-item;
  margin-left: 15px;
}

.express-lane-hero .row .useful-tools-section p {
  padding-bottom: 15px;
  margin-bottom: 0;
}

.express-lane-hero .row .useful-tools-section p:last-child {
  padding-bottom: 10px;
}

/*Tab Collapse*/

.download-logo {
  position: relative;
  margin-right: 5px;
  top: 3px;
  height: 15px;
  width: 15px;
  display: inline-block;
  background-position: 0 0;
  background-repeat: no-repeat;
}

#content .expresslane-download-list .nav-tabs li a {
  border: none;
  display: inline-block;
  padding: 10px 0 2px 0;
  color: #000000;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
}

.expresslane-download-list .nav-tabs li.active a {
  color: #000000;
  border: none;
}

.expresslane-download-list .nav-tabs li.active a {
  border-bottom: 3px solid #eb008b !important;
  font-weight: 700;
}

.expresslane-download-list .nav-tabs li a .glyphicon {
  display: none;
}

.download-logo.linux {
  background-image: url(../images/linux.svg);
}

.download-logo.windows {
  background-image: url(../images/windows.svg);
}

.download-logo.macos {
  background-image: url(../images/macos.svg);
}

.expresslane-download-list .panel-default>.panel-heading {
  color: #333333;
  background-color: #404549;
  border-color: #dddddd;
  padding: 20px;
  border-radius: 0;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ddd;
}

.expresslane-download-list .panel-default>.panel-heading .panel-title a {
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}

.expresslane-download-list .panel-default>.panel-heading .panel-title a .glyphicon-chevron-down {
  display: none;
}

.expresslane-download-list .panel-default>.panel-heading .panel-title a .glyphicon {
  position: absolute;
  right: 0;
  font-size: 26px;
  top: 0;
}

.expresslane-download-list .panel-default>.panel-heading .panel-title a .glyphicon-chevron-up {
  display: inline-block;
  color: #ffffff;
}

.expresslane-download-list .nav-tabs {
  border-bottom: 1px solid #dadce0;
}

.expresslane-download-list .nav-tabs li {
  margin-bottom: 0;
  border: none;
  padding-left: 6px;
}

.expresslane-download-list .nav-tabs li a {
  border: none;
  display: inline-block;
  padding: 10px 0 6px 0;
  color: #363c44;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
  cursor: pointer !important;
}

.expresslane-download-list ul.nav-tabs>li.active::after {
  content: '';
  display: block;
  width: 25px;
  height: 14px;
  position: absolute;
  z-index: 2;
  bottom: -14px;
  left: 31px;
  margin-left: -13px;
}

.expresslane-download-list .content .version-block {
  padding: 10px;
  padding-top: 28px;
  margin-bottom: 7px;
  border-bottom: 1px dotted #dadce0;
}

.expresslane-download-list .content .version-block .pill {
  background-color: #97999b;
  color: #fff;
  padding: 4px 15px 4px 15px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 22px;
}

.expresslane-download-list .content .version-block .strong {
  font-weight: 700;
  line-height: 22px;
}

.other-assets-list .other-assets-table {
  width: 100%;

  tr {
    width: 100%;
  }
}

.expresslane-download-list .content .version-block .versions-table {
  margin-top: -17px;
  width: 100%;
}

.expresslane-download-list .content .version-block .versions-table td .filename {
  font-size: 16px;
  font-weight: normal;
}

.expresslane-download-list .content .version-block .versions-table td .filesize {
  font-size: 14px;
  color: #818081;
}

.expresslane-download-list .content .version-block .versions-table td:nth-child(3) .splunk-btn {
  margin-top: 7px;
}

.expresslane-download-list .glyphicon-save {
  margin-right: 5px;
}

.expresslane-download-list .content .version-block .versions-table td:nth-child(3) {
  min-width: 50%;
  text-align: right;
}

label.splunk-input input,
.splunk-form label.splunk-input textarea {
  border: none;
  font-size: 16px;
  background-color: transparent;
  height: 50px;
  text-indent: 11px;
  width: 100%;
  padding-left: 2px;
}

.splunk-form label.splunk-input textarea {
  height: 100px !important;
  padding-top: 17px;
}

.splunk-form label.splunk-input input,
.splunk-form label.splunk-input textarea {
  color: #363c44;
  font-weight: 600;
  text-indent: 11px;
  width: 100%;
}

.splunk-form label.splunk-input .input-wrap {
  position: relative;
  z-index: 2;
}

.splunk-form label.splunk-input .feedback {
  position: absolute;
  right: 5px;
  top: 0 !important;
  z-index: 3;
  color: #fff;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  display: none;
}

.splunk-form label.splunk-input.error .feedback {
  color: #b3051a;
  display: block;
}

.splunk-form label.splunk-input .placeholder {
  -webkit-transition: top 0.3s, font-size 0.3s;
  transition: top 0.3s, font-size 0.3s;
  position: absolute;
  padding-left: 12px;
  top: 17px;
  z-index: 1;
  font-size: 16px;
  font-weight: normal;
  cursor: text;
  color: #72777a;
}

.splunk-form label.splunk-input {
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 2px !important;
}

.splunk-form label.splunk-input {
  border: 1px solid #97999b;
  overflow: hidden;
}

.splunk-form label.splunk-input.error {
  border-color: #b3051a;
  //background-color: #565c60;
}

.program-detail ul {
  list-style-type: disc !important;
}

.program-detail .nav-tabs {
  display: flex;
}

.program-detail .nav-tabs li.active a {
  border-bottom: 3px solid #e20082;
  font-weight: 700;
  color: #000;
}

.program-detail .nav-tabs li.feature-request a {
  background-color: buttonface;
  display: inline-block;
  padding: 10px 35px 6px 35px;
  color: #363c44 !important;
  margin-left: 0;
  margin-right: 10px;
  opacity: 1;
  font-size: 18px;
  border-radius: 0;
}

#sendFeedbackModal .nav-tabs {
  display: flex;
  justify-content: center;
}

#sendFeedbackModal .nav-tabs li.active a {
  border-bottom: 3px solid #e20082;
  font-weight: 700;
  color: #000;
}

#sendFeedbackModal .nav-tabs li a {
  border: none;
  display: inline-block;
  padding: 10px 35px 6px 35px;
  color: #363c44;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
  font-size: 18px;
  border-radius: 0;
  border: 0;
}

#sendFeedbackModal .label-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

#sendFeedbackModal .tab-content:before {
  position: absolute;
  content: '';
  height: 3px;
  background: radial-gradient(111.72% 98.15% at 111.72% 108.08%, #f89d1c 0%, #e20082 100%);
  top: 0px;
  width: 100.3%;
  left: -1px;
}

#sendFeedbackModal .nav-tabs>li>a:hover {
  background: none;
}

#sendFeedbackModal .splunk2-h4 {
  font-size: 21px;
  line-height: 110%;
}

#sendFeedbackModal .splunk2-h4.modal-title {
  padding-bottom: 0;
}

#sendFeedbackModal .modal-header {
  margin-bottom: 30px;
  border-bottom: 1px solid #dadce0;
  padding: 20px;
}

#sendFeedbackModal .modal-body {
  padding-top: 0;
}

#sendFeedbackModal .close-button {
  background: none;
  font-size: 16px;
  line-height: 16px;
  color: #0070f3;
  border: 0;
  font-weight: 600;
}

#sendFeedbackModal .modal-content {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

#sendFeedbackModal .btn-submit[disabled] {
  opacity: 0.4;
}

#sendFeedbackModal .modal-header .close {
  margin-top: -7px;
}

#sendFeedbackModal .close span {
  font-size: 40px;
}

#sendFeedbackModal .close {
  opacity: 0.5;
  height: 15px;
}

/*Dropdown*/
.splunk-form label.splunk-input.input-select {
  overflow: visible;
  margin-bottom: 8px;
}

.splunk-form label.splunk-input.input-select select:focus,
.splunk-form label.splunk-input.input-select select:active {
  outline: none;
}

#sendFeedbackModal .splunk-form select.splunk-custom-select {
  margin-top: 0;
  margin-bottom: 0;
  width: 100% !important;
  height: 50px !important;
  font-weight: normal !important;
  border-color: #969daa;
  color: #72777a;
  border: none;
}

#sendFeedbackModal .splunk-body p {
  color: #474444;
}

.splunk-form .splunk-custom-select-span.success {
  border-color: #2eb50b;
}

.splunk-form .splunk-form .splunk-custom-select-span:after {
  color: #fff;
  top: 12px !important;
}

.splunk-form .splunk-custom-select-span {
  border: 1px solid #97999b;
}

.splunk-form .splunk-custom-select-span .splunk-custom-select-spanInner {
  width: 100% !important;
  font-size: 16px;
}

.splunk-form .splunk-custom-select-span .splunk-custom-select-spanInner {
  width: calc(100% - 20px) !important;
  white-space: nowrap;
  overflow: hidden;
}

.splunk-form .splunk-custom-select-span.success span {
  color: #fff;
  font-weight: 600;
}

.splunk-form .splunk-custom-select-span {
  padding: 16px 12px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;
  color: #363c44;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 13px;
}

.splunk-form .splunk-custom-select-span:after {
  font-family: 'icomoon';
  content: '\e90a';
  position: absolute;
  top: 10px;
  right: 5px;
  bottom: 0;
  font-size: 16px;
  line-height: 30px;
  padding: 0 7px;
  background: transparent;
  color: #97999b;
  pointer-events: none;
}

.splunk-form .splunk-custom-select-span.success span {
  color: #fff;
  font-weight: 600;
}

.splunk-form .splunk-custom-select-span.success span {
  font-weight: bold;
}

.splunk-form .splunk-custom-select-span .splunk-custom-select-spanInner {
  font-size: 16px;
}

.splunk-form .splunk-custom-select-span .splunk-custom-select-spanInner {
  width: calc(100% - 20px) !important;
  white-space: nowrap;
  overflow: hidden;
}

.splunk-form .splunk-custom-select-span .splunk-custom-select-spanInner {
  width: 100% !important;
  font-size: 16px;
}

.splunk-form label.splunk-input.success {
  border-color: #2eb50b;
  //background-color: #6c7174;
}

.splunk-form label {
  display: block;
}

.splunk-lead p:last-child {
  padding-bottom: 0;
}

.bottom-spacing {
  padding-bottom: 35px;
}

.breadcrumb {
  background-color: #f0f3f7;
  padding: 10px 15px;
  height: 40px;
}

.breadcrumb .glyphicon-menu-right {
  font-size: 10px;
}

#content .breadcrumb a {
  color: #363c44;
  text-decoration: none;
}

#content .body-small {
  font-size: 15px;
  font-weight: normal;
  line-height: 19px;
}

.expresslane-download-list .nav-tabs li a:hover,
.expresslane-download-list .nav-tabs li a:focus {
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.expresslane-download-list .nav-tabs li:hover a {
  color: #2eb50b;
  font-weight: regular;
  opacity: 1;
  transition: color 0.5s;
}

.product-list {
  margin-top: 15px;
}

.product-list>.row {  
  border-radius: 8px;
  border: 0.5px solid #f0f3f7;
  padding: 25px;
  margin-bottom: 25px;
}

.product-list>.row:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2), 0 2px 5px 0 rgba(0,0,0,.19);
  transition: all .3s;
}

.product-list>.program-row-odd{
  background: #f0f3f7;
}

.product-list>.program-row-even{
  background: none;
  border: 0.5px solid #b2b2b1;
}


.product-list .splunk-green-cta,
.product-list .col-xs-3,
.product-list .col-sm-5 {
  font-weight: 600;
}

.media-content p {
  margin-bottom: 10px;
}

.top-spacing {
  margin-top: 20px;
}

.splunk-body p:last-child {
  padding-bottom: 0;
}

#content {

  .splunk2-lead-md,
  p.splunk2-lead-md {
    font-size: 21px;
    line-height: 120%;
  }

  .splunk-color.splunk-white {
    color: #ffffff;
  }

  .product-list p span {
    font-size: 18px !important;
    font-weight: normal;
  }

  .product-list p em,
  .product-list p strong,
  .media-content p strong {
    font-style: normal;
    font-weight: normal;
  }
}

.btn-upload {
  width: 255px;
  height: 36px; 
  font-size: 14px !important;
  margin-left: -12%;
}

@media (min-width: 480px) {
  #content {
    .splunk-body,
    p {
      font-size: 18px;
      line-height: 140%;
    }

    .splunk-lead p {
      font-size: 20px;
      line-height: 140%;
    }
  }

  #sendFeedbackModal .tab-content {
    margin: 35px 25px 0 25px;
    border: 1px solid #97999b;
    border-top: 0;
    padding: 15px 30px;
    position: relative;
  }

  .program-detail .tab-content {
    margin: 20px 0 0 0;
    border: 0;
    padding: 15px 30px;
    position: relative;
  }

  .program-detail .tab-content .tab-content {
    margin: 0;
    border: 0;
    padding: 0;
    position: relative;
  }

  .program-detail .tab-content .show {
    opacity: 100;
    display: block !important;
  }

  #sendFeedbackModal .modal-dialog {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  #sendFeedbackModal .nav-tab-container {
    padding: 0 15px;
  }

  .program-detail .nav-tab-container {
    padding: 0 15px;
  }

  #sendFeedbackModal .ahafeedback-iframe {
    border: 0;
    width: 100%;
    min-height: 800px;
  }
  .btn-upload {
    margin-left: 0;
    width: 300px;
  }
}

@media (min-width: 768px) {
  #content {
    .splunk-padding {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  #sendFeedbackModal .tab-content {
    margin: 35px 25px 0 25px;
    border: 1px solid #97999b;
    border-top: 0;
    padding: 15px 30px;
    position: relative;
  }

  .program-detail .tab-content {
    margin: 20px 0 0 0;
    border: 0;
    padding: 15px 30px;
    position: relative;
  }

  #sendFeedbackModal .modal-dialog {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  #sendFeedbackModal .nav-tab-container {
    padding: 0 15px;
  }

  .program-detail .nav-tab-container {
    padding: 0 15px;
  }

  #sendFeedbackModal .aha-feedback {
    width: 90%;
  }

  #sendFeedbackModal .ahafeedback-iframe {
    width: 100%;
    min-height: 800px;
  }
  
  .btn-upload {
      width: 300px;
      font-size: 16px !important;
      margin-left: 15%;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .express-lane-hero .useful-tools-section {
    right: 15px;
  }
}

@media (max-width: 991px) {
  #content {
    .download-logo.windows {
      background-position: -450px -26px;
    }

    #sendFeedbackModal .nav-tab-container {
      padding: 0 15px;
    }

    .col-splunk-4.text-right {
      text-align: left;
    }
  }

  @media (max-width: 1200px) {
    .express-lane-hero .row .col-splunk-4 p {
      line-height: 1.2;
    }
  }
}

@media (min-width: 992px) {
  #button-link-target {
    position: relative;
    top: 70px;
  }
  .btn-upload {
    font-size: 16px !important;
    margin-left: 29%;
}
}

@media (min-width: 1200px) {
  .expresslane-download-list .nav-tabs li {
    margin-right: 20px;
  }

  #sendFeedbackModal .nav-tab-container {
    margin: 0 20px 35px 20px;
  }

  #sendFeedbackModal .tab-content {
    margin: 35px 25px 0 25px;
    border: 1px solid #97999b;
    border-top: 0;
    padding: 15px 30px;
    position: relative;
  }

  .program-detail .tab-content {
    margin: 20px 0 0 0;
    border: 0;
    padding: 15px 30px;
    position: relative;
  }

  #sendFeedbackModal .modal-dialog {
    width: 930px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  #sendFeedbackModal .aha-feedback {
    width: 70%;
  }

  #sendFeedbackModal .ahafeedback-iframe {
    border: 0;
    width: 100%;
    min-height: 800px;
  }
}

#modal_image .modal-dialog {
  margin: 2.5vh auto;
}

#modal_image .modal-content {
  background: #000;
}

#modal_image .modal-body {
  max-height: 90vh;
}

#modal_image .modal-content {
  max-height: 95vh;
}

figure {
  min-height: 155px;
  border: 1px solid #cfd7db;
  position: relative;
  margin-bottom: 36px;
  background: #e3e2e2;
}

figure img {
  /* opacity: .8; */
  opacity: 100;
  max-width: 100%;
}

.image-preview-modal {
  & .modal-content {
    background: #000;

    & .modal-header {
      border: none;
      padding-bottom: 0;

      & button {
        font-size: 44px;
        color: #fff;
      }
    }
  }
}

.playcontainer {
  font-size: 48px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  z-index: 2;
  margin-top: -30px;
  margin-left: -25px;
}

a figcaption {
  float: left;
  line-height: 2.6;
  padding: 5px 9px;
  color: #fff;
  font-size: 13px;
  border-left: 1px solid #6a7175;
  font-family: 'proxima_nova';
  transition: 0.2s;
  height: 44px;
  text-align: left;
  background: #2a3135;
  opacity: 0.9;
  position: absolute;
  width: 100.5%;
  bottom: 0;
  left: -1px;
}

div.col-md-4 .media-cnt>a {
  max-width: 960px;
  *height: 195px;
  background-size: cover;
  background-position: center;
}

div img.thumb-img {
  content: none;
  display: block;
  width: 100%;
  height: 195px;
}

#modal_image.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

#modal_image .modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

.other-links a,
.other-links a:hover,
.other-links a:visited,
.other-links a:active {
  font-size: 16px;
  color: #ED0080;
}

.other-links {
  padding-top: 12px;
}

.splunk-form label.splunk-input.success .feedback {
  color: #ED0080;
  display: block;
}

@media (min-width: 1200px) {

  #modal_image .modal-dialog,
  #modal_image .modal-content {
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  #modal_image .modal-body {
    padding: 30px;
  }

  #modal_image {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.product-list .date-column {
  display: inline-block;
}

.product-list .date-column-past {
  width: 230px;
  display: inline-block;
}

.product-list .name {
  display: inline-block;
  word-break: break-word;
}

.splunk-form label.splunk-input .placeholder.small {
  z-index: 3;
  font-size: 12px;
  top: 5px;
}

.splunk-form label.splunk-input.success input,
.splunk-form label.splunk-input.focus input {
  padding-top: 15px;
}

.splunk-form label.splunk-input.focus .feedback {
  display: none;
}

.splunk-form label.splunk-input.success textarea,
.splunk-form label.splunk-input.focus textarea {
  padding-top: 20px;
}

.splunk-form label.splunk-input.focus {
  border: 1px solid #000;
  color: #000;
}

button,
select {
  text-transform: none;
}

.mandatory-field {
  color: red;
}

.display-none {
  display: none;
}

.description-template-text {
  display: inline-block;
  color: gray;
  font-style: italic;
  font-size: 11px;
  line-height: 15px;
}

@keyframes placeHolderShimmer {
  from {
    background-position: -400px 0;
  }

  to {
    background-position: 400px 0;
  }
}

.fake-loader-wrapper {
  display: flex;
  justify-content: space-around;
}

.video-fake-loader {
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to left, rgba(#d5dce5, 0.5), #fff, rgba(#d5dce5, 0.5));
  background-size: 400px auto;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: 100px;
}

.video-figure {
  max-height: 210px;
  display: flex;

  & img {
    object-fit: cover;
  }
}

.video-text {
  display: flex;
  flex-direction: column;
  margin-top: -25px;
  margin-bottom: 20px;
}

.upload-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .widget-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  & button {
    font-size: 18px;
  }

  & .upload-frame {
    width: 108%;
    height: 520px;
    margin-left: -27px;
    border: none;
  }

  & .mandatory-field {
    font-size: 12px;
    line-height: normal;
    padding-bottom: 10px;
  }
}

.doc-footer-link {
  border-right: 1px solid;
  padding-left: 5px;
}

.doc-footer-link:last-child {
  border: none;
  padding-right: 15px;
}

.thank-you-link {
  padding: 0 5px;
  color: white !important;
  text-decoration: underline !important;
}

.hr-divider {
  margin: 50px 0;
}

.useful-tools-title {
  text-transform: uppercase;
  font-family: 'proxima_nova', Arial, sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #656c76 !important;
}

.useful-tools-box {
  background: white !important;

  p {
    color: #363c44 !important;
    font-weight: bold;
    line-height: 26px;

    a {
      padding: 0 5px;
    }
  }

  div {
    color: #363c44 !important;
  }
}

.release-date-title {
  margin-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 18px !important;
  line-height: 22px;
  color: gray !important;
  font-family: 'splunk_data_sans', Helvetica, Arial, sans-serif;
}

.table-wrapper {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.description-text {
  word-break: break-word;
}

// temp fixes provided by web-mkt team
#app>div>div.header {
  min-height: 93px;
}

.global-header-v2-wrapper {
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  #app>div>div.header {
    min-height: initial;
  }

  .express-lane-hero .hero-voc-image-detail img {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0 50px 20px 25px;
  }

  #content #send-feedback-section #button-link-target {    
    width: 100%;
  }
}

.error-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &>span:first-of-type {
    font-weight: bold;
  }

  &>div {
    margin-top: 10px;

    .link {
      color: #e20082 !important;
    }
  }
}

.empty-program {
  text-align: center;
}

.filter-select {
  width: 259px;
  height: 36px;
  margin-left: 15px;
  background-image: url(../images/arrowSelect.png);
  background-position: calc(100% - 14px) calc(1em + 0px), calc(100% - 9px) calc(1em + 2px), 100% 0;
  background-size: 9px 6px, 7px 6px, 2.5em 2.5em;
  background-repeat: no-repeat;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-color: lightgray;
  color: black;
  border-radius: 2px;
  padding-left: 12px;
}

.filter {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  span {
    color: black;
  }
}
