.tabs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;  

  .tabs {
    & > button {
      width: auto;
      text-align: center;
      background: #f0f3f7;
      border-radius: 5px 5px 0 0;
      color: #363c44;
      border: none;
      padding: 15px;
      margin-right: 16px;
      font-size: 16px;
      line-height:12px;
      justify-content: center;
      border-bottom: 1px solid #f0f3f7;
      img {
        float: left;
        margin-top: 2px;
        margin-right: 26px;
      }

    }
    .content {
      padding-top: 35px;
      border-top: 2px solid #E2E8F0;
      margin-top: -2px;
    }
    .active {
      border-bottom: 3px solid #e20082;
      font-weight: bold;
    }
  }
}
