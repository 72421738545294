#sendFeedbackModal {
  .nav-tabs {
    display: flex;
    justify-content: center;
    li.active a {
      border-bottom: 3px solid #e20082;
      color: #000;
      font-weight: 600;
    }
    li a {
      border: 0;
      border-radius: 0;
      color: #363c44;
      display: inline-block;
      font-size: 18px;      
      margin-left: 15px;
      margin-right: 15px;
      opacity: 1;
      padding: 10px 35px 6px 35px;
    }
    & > li > a:hover {
      background: none;
    }
  }

  .label-text {
    color: #000;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding-bottom: 10px;
    &.required::after {
      color: red;
      content: '*';
      padding: 5px;
    }
  }

  .tab-content {
    border: 1px solid #97999b;
    border-top: 0;
    margin: 35px 25px 0 25px;
    padding: 15px 30px;
    position: relative;

    &:before {
      background: radial-gradient(111.72% 98.15% at 111.72% 108.08%, #f89d1c 0%, #e20082 100%);
      content: '';
      height: 3px;
      left: -1px;
      position: absolute;
      top: 0px;
      width: 100.3%;
    }
  }

  .splunk2-h4 {
    font-size: 18px;
    line-height: 110%;
    &.modal-title {
      padding-bottom: 0;
    }
  }

  .modal-header {
    align-items: center;
    border-bottom: 1px solid #dadce0;
    display: flex;
    margin-bottom: 30px;
    padding: 20px;

    .close-wrap {
      flex: auto;
      position: relative;
    }

    .close {
      margin-top: 0;
      opacity: 0.5;
      span {
        font-size: 40px;
      }
    }
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-content {
    border-radius: 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  .close-button {
    background: none;
    border: 0;
    color: #0070f3;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .btn-submit[disabled] {
    opacity: 0.4;
  }
  
  .modal-dialog {
    border-radius: 0;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .bug-field {
    margin-bottom: 15px;
  }

  .attach-wrap {
    padding: 30px;
  }

  .splunk-form select.splunk-custom-select {
    border: none;
    border-color: #969daa;
    color: #72777a;
    font-weight: normal !important;
    height: 50px !important;
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;
  }

  .splunk-body p {
    color: #474444;
  }

  .feedback-content {
    border: 1px solid #969DAA;
    border-top: 2px solid #ED0080;
    padding: 15px 30px 30px;
  }

  .nav-tab-container {
    padding: 0 15px;
  }

  .ahafeedback-iframe {
    border: 0;
    min-height: 800px;
    width: 100%;
  }

  @media (min-width: 768px) {  
    .aha-feedback {
      width: 90%;
    }
  }

  @media (min-width: 1200px) {
    .nav-tab-container {
      margin: 0 20px 35px 20px;
    }
  
    .modal-dialog {
      width: 930px;
    }
  
    .aha-feedback {
      width: 70%;
    }
  }
}
