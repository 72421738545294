#app > div {
  position: relative;
}

.spinner {
  background: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 100%;

  .loading-img {
    transform: scale(1.6);
    display: inline-block;
    position: relative;
    top: 50vh;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  }
}
