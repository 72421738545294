.survey-pop-up {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.survey-pop-up .survey-title {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #ed0080;
    font-weight: 700;
    font-size: 40px;
    margin-top: 15px;
}



.survey-pop-up .survey-body {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.survey-pop-up .survey-body .survey-pop-up .survey-body.survey-checkbox {
    margin-top: 30px;
    margin-bottom: 15px;
}

.survey-pop-up .survey-body .survey-pop-up .survey-body.survey-checkbox .form-check-label {
    font-weight: 600;
    margin-left: 10px;
}


.survey-body-message {
    font-size: 29px;
    font-weight: 500;
}

.survey-buttons {
    margin-top: 40px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.survey-pop-up .btn {
    border-radius: 50px;
    font-size: 20px;
    background-color: #ed0080;
    border-color: #ed0080;
    background-image: none;
    color: #fff;
}

.survey-pop-up .modal-header {
    border-bottom: transparent;
}

.survey-pop-up .modal-footer {
    border-top: transparent;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
}

.survey-pop-up .modal-body form {
    width: 100%;
}